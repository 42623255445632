<template>
  <div class="msg__wrapper">
    <div class="body">
      <div id="common_box">
        <div class="commom_title">
          <a-row type="flex">
            <a-col :span="8">
              <a-row>
                <a-col :span="2">
                  <div class="commom_line"></div>
                </a-col>
                <a-col :span="7">方案征集</a-col>
              </a-row>
            </a-col>
          </a-row>
        </div>
        <a-spin :spinning="btnLoading">
          <div class="content">
            <a-row type="flex">
              <a-col :span="12">
                <a-row type="flex" justify="center">
                  <a-col :span="22">
                    <div class="content_tips">选择希望推送的人员类型</div>
                  </a-col>
                </a-row>
                <a-row type="flex" justify="center">
                  <a-col :span="22">
                    <div class="content_desc">
                      可对希望推送的人员标签进行定制，便于系统更精准为您推送到相关人员处，便于
                      <br />需求更快更精准的被响应。
                    </div>
                  </a-col>
                </a-row>
                <a-row type="flex" justify="center">
                  <a-col :span="22">
                    <a-form-model
                      :model="leftForm"
                      ref="leftForm"
                      :rules="leftRules"
                      labelAlign="left"
                      :label-col="{ span: 4 }"
                      :wrapper-col="{ span: 18 }"
                    >
                      <a-form-model-item label="方案标题" prop="title">
                        <a-input placeholder="请输入方案标题" v-model="leftForm.title" />
                      </a-form-model-item>
                      <!-- <a-form-model-item label="人员类别" prop="type">
                        <a-radio-group
                          name="radioGroup"
                          v-model="leftForm.type"
                          @change="handleGetPositionData"
                        >
                          <a-radio
                            :value="item.id"
                            v-for="item in personnelType"
                            :key="item.id"
                          >{{ item.name }}</a-radio>
                        </a-radio-group>
                      </a-form-model-item>-->
                      <a-form-model-item :label="leftForm.type === 1 ? '工种' : '专业'" prop="position">
                        <a-select
                          v-model="leftForm.position"
                          :options="dictPosition"
                          :placeholder="`请选择${leftForm.type === 1 ? '工种' : '专业'}`"
                          allowClear
                          @change="handleGetSkill(leftForm.type, $event)"
                        ></a-select>
                      </a-form-model-item>
                      <a-form-model-item label="基本技能" prop="skill">
                        <a-select
                          ref="select"
                          v-model="leftForm.skill"
                          allowClear
                          placeholder="请选择基本技能"
                          :options="baseSkillDtoList"
                        ></a-select>
                      </a-form-model-item>
                      <a-form-model-item
                        label="专业技能"
                        class="required"
                        :rules="{ validator: validPositionId }"
                        v-if="leftForm.type != 2"
                        prop="positionId"
                      >
                        <a-select
                          v-model="leftForm.positionId"
                          placeholder="请选择专业技能"
                          allowClear
                          :options="professionalSkillDtoList"
                        ></a-select>
                      </a-form-model-item>
                      <a-form-model-item
                        label="从业资格证"
                        class="required"
                        v-if="leftForm.type == 2"
                        :rules="{ validator: validQualificationCertificate }"
                        prop="qualificationCertificate"
                      >
                        <a-radio-group name="radioGroup" v-model="leftForm.qualificationCertificate">
                          <a-radio :value="1">安全员</a-radio>
                          <a-radio :value="2">建造师</a-radio>
                          <a-radio :value="0">不限</a-radio>
                        </a-radio-group>
                      </a-form-model-item>

                      <a-form-model-item label="工作经历" prop="experience">
                        <a-radio-group name="radioGroup" v-model="leftForm.experience">
                          <a-radio :value="1">30万机组以下</a-radio>
                          <a-radio :value="2">30-60万机组</a-radio>
                          <a-radio :value="3">100万及以上机组</a-radio>
                          <a-radio :value="0">不限</a-radio>
                        </a-radio-group>
                      </a-form-model-item>
                      <a-form-model-item label="职称等级" prop="skillLevelCertificate">
                        <a-radio-group name="radioGroup" v-model="leftForm.skillLevelCertificate">
                          <a-radio :value="1">初级</a-radio>
                          <a-radio :value="2">中级</a-radio>
                          <a-radio :value="3">高级</a-radio>
                          <a-radio :value="-1">特殊工种证书</a-radio>
                          <a-radio :value="0">不限</a-radio>
                        </a-radio-group>
                      </a-form-model-item>
                    </a-form-model>
                  </a-col>
                </a-row>
              </a-col>
              <a-col :span="12">
                <a-row type="flex" justify="center">
                  <a-col :span="22">
                    <div class="content_tips">编辑方案需求</div>
                  </a-col>
                </a-row>
                <a-row type="flex" justify="center">
                  <a-col :span="22">
                    <div class="content_desc title">
                      此处编辑自己的详细方案需求，便于需求响应者更快捷的了解您的需求，从而进行响应。
                    </div>
                  </a-col>
                </a-row>
                <a-row type="flex" justify="center">
                  <a-col :span="22">
                    <a-form-model
                      :model="rightForm"
                      ref="rightForm"
                      :rules="rightRules"
                      labelAlign="left"
                      :label-col="{ span: 4 }"
                      :wrapper-col="{ span: 19 }"
                    >
                      <a-form-model-item label="需求内容" prop="content">
                        <a-textarea
                          :rows="4"
                          placeholder="请输入需求内容"
                          :maxLength="200"
                          v-model="rightForm.content"
                        />
                      </a-form-model-item>
                      <a-form-model-item label="附件上传" class="form-item">
                        <a-upload
                          name="file"
                          :multiple="true"
                          :action="action"
                          :headers="headers"
                          @change="handleFileListChange"
                          :beforeUpload="uploadBefore"
                          :remove="handleFileListRemove"
                          :fileList="fileList"
                          class="avatar-uploader"
                          accept=".doc, .xlsx, .pdf"
                        >
                          <a-button> <a-icon type="upload" />上传附件（.doc, .xlsx, .pdf） </a-button>
                        </a-upload>
                      </a-form-model-item>
                      <a-form-model-item label="方案报价" prop="price">
                        <a-input-number
                          :max="999999999"
                          :min="0"
                          placeholder="请输入"
                          v-model="rightForm.price"
                        />&nbsp&nbsp&nbsp&nbsp元
                      </a-form-model-item>
                      <a-form-model-item label="需求时间" prop="endTime" class="form-item">
                        <a-date-picker
                          value-format="YYYY-MM-DD"
                          v-model="rightForm.endTime"
                          :disabledDate="disabledDate"
                        />
                        <span class="msg_tips">
                          <span style="color: #ff0000">*</span>需求截止时间内若未确定合作人选，可在“控制台-方案征集管理”中
                          取消发布。
                        </span>
                      </a-form-model-item>
                      <a-form-model-item label="方案接收邮箱" prop="mailbox">
                        <a-input type="input" placeholder="请输入方案接收邮箱" v-model="rightForm.mailbox" />
                      </a-form-model-item>
                    </a-form-model>
                  </a-col>
                </a-row>
              </a-col>
            </a-row>
            <a-row type="flex" justify="center">
              <a-col :span="4">
                <a-button type="primary" class="msg_btn" @click="Submit">创建方案征集需求</a-button>
              </a-col>
            </a-row>
          </div>
        </a-spin>

        <a-modal
          v-model="visible"
          centered
          @ok="handleOk"
          :ok-button-props="{ style: { display: 'none' } }"
          :cancel-button-props="{ style: { display: 'none' } }"
        >
          <div class="modal_title">待支付金额</div>
          <div class="modal_money">￥ 2,000</div>
          <div class="modal_code"></div>
          <div class="modal_title">
            支付宝扫描上方二维码，完成支付后，可在“企业
            <br />中心-方案用工管理”中查看响应情况。
          </div>
        </a-modal>
        <a-modal
          centered
          v-model="isShow"
          okType="text"
          title="创建成功"
          @ok="handleOk"
          :footer="null"
          :ok-button-props="{ style: { display: 'none' } }"
          :cancel-button-props="{ style: { display: 'none' } }"
        >
          <p class="modal_text">
            您的方案需求已成功创建，可在“控制台-方案
            <br />管理”进行付款
          </p>
          <div>
            <router-link
              to="/msgManager/msgManagerDemand"
              style="margin-left: 175px; font-size: 18px"
            >前往方案管理 >></router-link
            >
          </div>
        </a-modal>
      </div>
    </div>
  </div>
</template>

<script>
import {
  employmentEmploybaseSkill,
  employmentEmploypersonnelType,
  qualificationCertificatePage,
  employmentEmployDictPosition
} from '@/api/workDemand'
import { EnterpriseMaterialDemateController } from '@/api/msg'
import VueCookeis from 'vue-cookies'
import { ACCESS_TOKEN } from '@/store/mutation-types'

var emaiReg = /^\w{3,}(\.\w+)*@[A-z0-9]+(\.[A-z]{2,5}){1,2}$/

export default {
  name: 'Index',
  data () {
    return {
      isShow: false,
      visible: false,
      personnelType: [],
      baseSkillDtoList: [],
      options: [],
      professionalSkillDtoList: [],
      // 从业资格
      requirements: [
        { name: '安全员', id: '1' },
        { name: '建造师', id: '2' },
        { name: '不限', id: '0' }
      ],
      leftForm: {
        type: 2
      },
      rightForm: {
        materialDemandFiles: []
      },
      leftRules: {
        title: { required: true, message: '请输入方案标题', trigger: 'blur' },
        type: { required: true, message: '请选择人员类别', trigger: 'change' },
        position: { required: true, message: '请选择专业', trigger: 'change' },
        skill: { required: true, message: '请选择基本技能', trigger: 'change' },
        experience: { required: true, message: '请选择工作经历', trigger: 'change' },
        skillLevelCertificate: { required: true, message: '请选择职称等级', trigger: 'change' }
      },
      rightRules: {
        content: { required: true, message: '请输入需求内容', trigger: 'blur' },
        price: { required: true, message: '请输入方案报价', trigger: 'blur' },
        endTime: { required: true, message: '请输入需求时间', trigger: 'change' },
        mailbox: [
          { required: true, message: '请输入邮箱', trigger: 'blur' },
          { pattern: emaiReg, message: '邮箱格式不正确', trigger: 'blur' }
        ]
      },
      dictPosition: [],
      btnLoading: false,
      action: `${process.env.VUE_APP_API_BASE_URL}/api/enterprise/employmentFile/EnterpriseFileController/uploadFile`,
      headers: {
        token: VueCookeis.get(ACCESS_TOKEN),
        clientId: process.env.VUE_APP_CLIENT_ID
      },
      fileList: []
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    validPositionId (rule, value, callback) {
      if (this.leftForm.personnelType == '2') {
        callback()
      } else {
        if (!value) {
          callback(new Error('请选择专业技能'))
        }
        callback()
      }
    },
    validQualificationCertificate (rule, value, callback) {
      if (this.leftForm.personnelType == '1') {
        callback()
      } else {
        if (value === undefined || value === '' || value === null) {
          callback(new Error('请选择从业资格证'))
        }
        callback()
      }
    },
    handleGetPositionData (e) {
      const type = e.target.value
      this.dictPosition = []
      this.$set(this.leftForm, 'position', undefined)
      this.$set(this.leftForm, 'skill', undefined)
      this.$set(this.leftForm, 'qualificationCertificate', undefined)
      this.$set(this, 'baseSkillDtoList', [])
      this.$nextTick(() => {
        this.$refs.leftForm.clearValidate([`positionId`, `qualificationCertificate`])
      })
      // 职位
      if (type) {
        employmentEmployDictPosition({ type }).then((res) => {
          const data = res.data.map((item) => ({
            ...item,
            label: item.majorName,
            value: item.id
          }))
          this.$set(this, 'dictPosition', data)
        })
        this.handleGetSkill(type, null)
      }
    },
    handleGetSkill (type, positionId) {
      console.log(type, positionId)
      this.$set(this, 'professionalSkillDtoList', [])
      this.$set(this.leftForm, 'positionId', undefined)
      // 技能
      employmentEmploybaseSkill({
        type,
        positionId
      }).then((res) => {
        if (res.data) {
          !positionId &&
            this.$set(
              this,
              'baseSkillDtoList',
              res.data.baseSkillDtoList.map((item) => ({ ...item, label: item.name, value: item.id })) || []
            )
          positionId &&
            this.$set(
              this,
              'professionalSkillDtoList',
              res.data.professionalSkillDtoList.map((item) => ({ ...item, label: item.name, value: item.id })) || []
            )
        }
      })
    },
    init () {
      this.personnelType = []
      this.handleGetPositionData({ target: { value: 2 } })
      const params = {
        pageIndex: 1,
        pageSize: 100
      }
      employmentEmploypersonnelType(params)
        .then((res) => {
          res.data.forEach((v) => {
            v.label = v.name
            v.value = v.id
          })
          this.personnelType = res.data
        })
        .catch((err) => {
          console.log(err)
        })
      // const query = {
      //   pageIndex: 1,
      //   pageSize: 100
      // }
      // qualificationCertificatePage(query)
      //   .then(res => {
      //     res.data.forEach(v => {
      //       v.label = v.name
      //       v.value = v.id
      //     })
      //     this.requirements = res.data
      //   })
      //   .catch(err => {
      //     console.log(err)
      //   })
    },
    Submit () {
      const fun = async () => {
        var leftResult, rightResult
        await this.$refs.leftForm.validate((valid) => {
          leftResult = valid
        })
        await this.$refs.rightForm.validate((valid) => {
          rightResult = valid
        })
        if (leftResult && rightResult) {
          this.$confirm({
            title: '方案征集创建确认',
            content: `确认创建方案征集吗？（*请仔细核对方案接收邮箱是否有误，方案被响应后方案将发送至该接收邮箱【${this.rightForm.mailbox}】）`,
            onOk: () => {
              return new Promise((resolve) => {
                EnterpriseMaterialDemateController({
                  ...this.leftForm,
                  ...this.rightForm
                })
                  .then(() => {
                    this.isShow = true
                    this.$set(this, 'leftForm', { type: 2 })
                    this.$set(this, 'rightForm', { materialDemandFiles: [] })
                    this.fileList = []
                  })
                  .finally(() => {
                    resolve(true)
                  })
              })
            }
          })
        }
      }
      fun()
    },
    uploadBefore (file) {
      const { size, name } = file
      const typeList = ['pdf', 'xlsx', 'doc']
      const dotIdx = name.lastIndexOf('.') // 获取最后一个.的位置
      const suffix = name.substr(dotIdx + 1) // 获取文件后缀
      if (!typeList.includes(suffix.toLowerCase())) {
        this.$message.warning('文件格式不正确')
        return false
      } else if (size > 100 * 1024 * 1024) {
        this.$message.warning('文件大小不要超过100MB')
        return false
      } else {
        return true
      }
      // const formData = new FormData()
      // formData.append('file', file.file)
      // uploadFile(formData).then(res => {
      //   this.form.fileName = `${process.env.VUE_APP_FILE_PATH}${res.path}`
      //   const { fileList = [] } = this.form
      //   this.$set(this.form, 'fileList', [
      //     ...fileList,
      //     {
      //       status: 'done',
      //       uid: res.fileId,
      //       name: res.fileName,
      //       url: `${process.env.VUE_APP_FILE_PATH}${res.path}`
      //     }
      //   ])
      //   this.$set(this.form, 'fileSize', res.fileSize)
      //   this.$refs.form.clearValidate('fileList')
      // })
    },
    handleFileListChange (info) {
      if (info.file.status) {
        let fileList = [...info.fileList]
        fileList = fileList.map((file) => {
          if (file.response) {
            file.url = file.response.path
          }
          return file
        })
        this.fileList = fileList
        this.rightForm.materialDemandFiles = fileList.map((item) => ({
          path: item.url,
          name: item.name,
          uid: item.uid
        }))
      }
    },
    handleFileListRemove (file) {
      this.fileList = this.fileList.filter((item) => item.uid !== file.uid)
      this.rightForm.materialDemandFiles = this.rightForm.materialDemandFiles.filter(
        (item) => (item) => item.uid !== file.uid
      )
      return true
    },
    disabledDate (time) {
      return this.formatTime(time) < this.formatTime(new Date())
    },
    handleOk () {},
    onChange () {}
  }
}
</script>

<style lang="less">
@import url('../../common/style');
@import url('./index');
.form-item {
  .ant-form-item-children {
    display: flex;
    align-items: center;
  }
  .msg_tips {
    display: block;
    margin-left: 10px;
  }
}
</style>
